/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import CloseIcon from '@material-ui/icons/Close';
import { trim } from 'lodash';
import SliderCard from '../../application/SliderCard';
import DetailPackage from '../../application/Package/DetailPackage';
import { Rules } from '../../../helpers/RHFRules';
import {
  CleanCart,
  getServiceFeatureCodeBool,
  CalcPriceWithDiscountPackage,
  CalcDiscountPackage,
} from '../../../helpers/cartUtils';
import TableModalExt from './TableModal/TableModalExt';
import CardModalExt from './Responsive/CardModalExt';
import { getParmeterConfigAction } from '../../../actions/general.action';
import {
  setStatusCart,
  addToCartAction,
  deleteOfCartAction,
  applyPromotionCodeAction,
  getPackageBaseCustomerAction,
  getPackagesAction,
  UpdateQuantityCartAction,
  getPriceByLicenseTypeAction,
  UpdateQuantityPriceCartAction,
} from '../../../actions/home.action';
import {
  GET_PACKAGES_FROM_LOCAL,
} from '../../../types/home.type';

import { addLocalStorageEncript, getLocalStorageEncript, clearLocalStorageStore } from '../../../helpers/storageUtils';
import {
  getQuantityByTypeAction, getPriceLicenseByQuantityAction, addPricesLicenseAction, addQuantityLicenseAction,
} from '../../../actions/localStorage.action';
import { roundDecimalValue } from '../../../helpers/generalUtils';
import Loading from '../Loading';
import {
  URL_CONTACT_COLNOMINA,
} from '../../../config/config';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFFF',
  },
  titleModalCart: {
    ...theme.home.cart.title,
    fontWeight: 700,
    fontSize: 25,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  tableItemsCart: {
    minHeight: '420px',
    maxHeight: '420px',
    overflow: 'auto',
    '@media (max-width: 1080px)': {
      minHeight: '280px',
      maxHeight: '420px',
    },
  },
  tableCotainer: {
    marginBottom: 20,
    borderRadius: 5,
  },
  table: {
    minWidth: 300,
  },
  tableHead: {
    ...theme.home.cart.tableHead,
    heigth: 35,
  },
  dialogTitle: {
    ...theme.home.cart.dialogTitle,
  },
  dialogContent: {
    ...theme.home.cart.dialogContent,
  },
  promoForm: {
    ...theme.home.cart.promoForm,
    width: 'fit-content',
    display: 'flex',
    alignItems: 'flex-start',
    padding: 15,
    marginTop: 15,
    marginBottom: 20,
  },
  promoFormNoResponsive: {
    ...theme.home.cart.promoFormNoResponsive,
    width: 'fit-content',
    display: 'flex',
    alignItems: 'flex-start',
    padding: 15,
    marginTop: 15,
    marginBottom: 20,
  },
  divider: {
    marginBottom: 20,
  },
  totalWrapper: {
    float: 'right',
  },
  totalText: {
    ...theme.home.cart.totalText,
    fontSize: 20,
    textAlign: 'right',
  },
  taxInclude: {
    fontSize: 14,
    color: '#B4B4B4',
    textAlign: 'right',
    marginBottom: 15,
  },
  btnPromotional: {
    ...theme.home.cart.btnPromotional,
  },
  btnPromotionalResponsive: {
    ...theme.home.cart.btnPromotionalResponsive,
  },
  tableCell: {
    ...theme.home.cart.tableCell,
    fontWeight: 900,
    padding: 8,
  },
  rowCell: {
    fontWeight: 700,
    cursor: 'default',
  },
  tableCellItems: {
    padding: 8,
  },
  textPromotional: {
    ...theme.home.cart.textPromotional,
    marginRight: 10,
    ...theme.home.cart.colorTextPromotional,
  },
  btnContinue: {
    ...theme.home.cart.btnContinue,
  },
  btnContinueResponsive: {
    fontSize: '20px',
    ...theme.home.cart.btnContinueResponsive,
  },
  btnRemoveFromCart: {
    ...theme.home.cart.btnRemoveFromCart,
    padding: '0',
  },
  dvTitlePopup: {
    width: '100%',
  },
  btnAddProducts: {
    ...theme.home.cart.btnAddPackages,
    '&:hover': {
      ...theme.home.cart.hoverAddProducts,
    },
  },
  infoDescountText: {
    ...theme.home.cart.discountText,
    fontSize: 14,
  },
  infoDescountTextResponsive: {
    ...theme.home.cart.infoDescountTextResponsive,
    fontSize: 14,
  },
  observationText: {
    color: '#fff',
    fontSize: 14,
  },
  linkContact: {
    color: '#16B1F3',
    fontSize: 14,
  },
  backgroundDialogResponsive: {
    ...theme.home.cart.backgroundDialogResponsive,
  },
  tamIcon: {
    transform: 'scale(0.85)',
  },
}));

const CartModalExt = (props) => {
  // eslint-disable-next-line prefer-const
  let { idParam, quantityParam, reloadParam } = useParams();

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const qIdParam = params.get('idParam');
  const qQuantityParam = params.get('quantityParam');
  if (idParam === undefined && qIdParam) idParam = qIdParam;
  if (quantityParam === undefined && qQuantityParam) quantityParam = qQuantityParam;
  if (reloadParam === 'true') {
    localStorage.setItem('refreshPage', true);
  }

  const {
    isOpen,
    setIsCartOpen,
    closePopup,
    loading,
    fullWidth,
    onClose,
    cart,
    addToCart,
    deleteOfCart,
    applyPromotionCode,
    promoCode,
    history,
    userInfo,
    appName,
    packageBase,
    getPackageBaseCustomer,
    packages,
    getQuantityByType,
    getPriceLicenseByQuantity,
    getPackages,
    UpdateQuantityCart,
    getPriceByLicenseType,
    pricesLicense,
    quantityLicense,
    addPricesLicense,
    addQuantityLicense,
    source,
    applicationInfo,
    getParmeterConfigAction,
    parameterApp,
  } = props;

  const dispatch = useDispatch();
  const [isShownId, setIsShownId] = useState(0);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const EMPLOYEE_TYPE = 'EMPLOYEE';
  const { enqueueSnackbar } = useSnackbar();
  const [showloading, setShowLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [featuresPackage, setFeaturesPackage] = useState([]);
  const [cartAddExt, setCartAddExt] = useState([]);
  const [cargadoCartExt, setCargadoCartExt] = useState(false);
  const [refreshPage, setRefreshPage] = React.useState(true);
  const [quantityLicenseLocalEmployee] = useState(getQuantityByType(EMPLOYEE_TYPE));
  const [isDisabled, setIsDisabled] = useState(true);
  const classes = useStyles();
  const localCart = getLocalStorageEncript('cart') ? JSON.parse(getLocalStorageEncript('cart')) : null;
  const localPromoCode = getLocalStorageEncript('promoCode') ? JSON.parse(getLocalStorageEncript('promoCode')) : promoCode;
  let cartClean = CleanCart(localCart?.length > 0 ? localCart : cart, localPromoCode, appName);

  const {
    handleSubmit, control, errors, reset,
  } = useForm();

  React.useEffect(() => {
    setTimeout(() => {
      if (refreshPage && !reloadParam) {
        const refresh = localStorage.getItem('refreshPage');
        if (refresh === 'true' && source !== 'licenseWeb') {
          localStorage.setItem('refreshPage', false);
          window.location.reload();
        }
      }
      setRefreshPage(false);
    }, 900);
  });

  useEffect(() => {
    const storeLanding = getLocalStorageEncript('storeLanding');
    if (!storeLanding) {
      addLocalStorageEncript(true, 'storeLanding');
    }
  }, []);

  useEffect(() => {
    if (appName && appName !== '') {
      getParmeterConfigAction(appName, 'ParameterApps');
    }
  }, []);

  /**
   * Cargar cuando trae parametros el CartAdded en el local
   */
  useEffect(() => {
    if (idParam) {
      let cartAddBefore = null;
      const stringCartAddedEncrypt = getLocalStorageEncript('cartAdded');
      if (stringCartAddedEncrypt !== undefined) { cartAddBefore = JSON.parse(stringCartAddedEncrypt); }
      const cartNew = {
        packageCode: idParam,
        appName,
        number: quantityParam,
      };

      if (cartAddBefore === null) {
        cartAddBefore = [];
      }
      const indexFound = cartAddBefore.findIndex((item) => item.packageCode === idParam);
      if (indexFound >= 0) {
        const currentItem = cartAddBefore.find((item) => item.packageCode === idParam);
        if (currentItem) {
          if (currentItem.number !== quantityParam) {
            // limpia local storage codigo promocion si ya existe el producto con diferente cantidad
            localStorage.removeItem('promoCode');
          }
        }
        cartAddBefore.splice(
          cartAddBefore.findIndex((item) => item.packageCode === idParam),
          1,
        );
      } else {
        // limpia local storage codigo promocion si el producto es nuevo
        localStorage.removeItem('promoCode');
      }
      cartAddBefore.push(cartNew);
      addLocalStorageEncript(JSON.stringify(cartAddBefore), 'cartAdded');
      console.log('indexFound', indexFound);
      console.log('cartAddBefore', cartAddBefore);
    }

    // Cargo lo q este en el localStorage agregados
    let cartAddedStorage = null;
    const stringCartAddedEncrypt = getLocalStorageEncript('cartAdded');
    if (stringCartAddedEncrypt !== undefined) { cartAddedStorage = JSON.parse(stringCartAddedEncrypt); }
    setCartAddExt(cartAddedStorage);
  }, [idParam]);

  useEffect(() => {
    if (source !== 'licenseWeb') {
      // eslint-disable-next-line no-unused-expressions
      cartAddExt?.length && loadCartCrosscutting();
    }
  }, [cartAddExt, packages]);

  useEffect(() => {
    if (!cargadoCartExt) {
      // Save Package in Reducer
      const packageLocal = JSON.parse(getLocalStorageEncript('packages') || '[]');
      if (packageLocal.length === 0 || packageLocal?.base?.length === 0) {
        getPackages(appName);
      } else {
        // Save Package in Reducer
        dispatch({ type: GET_PACKAGES_FROM_LOCAL, payload: packageLocal });
      }
      setCargadoCartExt(true);
    }
    if (packages?.hasOwnProperty('base') && getLocalStorageEncript('packages') === undefined) {
      addLocalStorageEncript(JSON.stringify(packages), 'packages');
    }
  }, [packages]);

  useEffect(() => {
    if (cart.length > 0) {
      saveCartReducerToLocalStore();
      cartClean = CleanCart(cart, promoCode, appName);
    }
    if (source !== 'licenseWeb') {
      setIsCartOpen(true);
    }
  }, [cart]);

  useEffect(() => {
    if (promoCode) {
      addLocalStorageEncript(JSON.stringify(promoCode), 'promoCode');
    }
  }, [promoCode]);

  /**
   * Cargamos los precios de las licencias
   */
  useEffect(() => {
    if (getLocalStorageEncript('pricesLicense') === undefined) {
      getPriceByLicenseType();
    }
  }, [getPriceByLicenseType]);

  /**
   * Almacena en localStorage los datos de precios y listado de cantidades posibles para seleccion
   */
  useEffect(() => {
    pricesLicense && addPricesLicense(pricesLicense);
    quantityLicense && addQuantityLicense(quantityLicense);
  }, [pricesLicense]);

  /**
   *
   * @param {Identificador de busqueda} packageCode
   * @returns objeto package
   */
  const getCartPackage = (packageCode) => {
    let packageToAdd = packages?.base?.find(
      (pkg) => pkg.packageCode === trim(packageCode),
    );

    if (packageToAdd === undefined) {
      packageToAdd = packages?.complementary?.find(
        (pkg) => pkg.packageCode === trim(packageCode),
      );
    }

    if (packageToAdd === undefined) {
      packageToAdd = packages?.offers?.find(
        (pkg) => pkg.packageCode === trim(packageCode),
      );
    }

    if (packageToAdd === undefined) {
      packageToAdd = packages?.combo?.find(
        (pkg) => pkg.packageCode === trim(packageCode),
      );
    }
    return packageToAdd;
  };

  const getSubtotal = (cart) => {
    const total = cart.reduce(
      (previousValue, currentValue) => (
        (((currentValue.base * (currentValue.isEmployee ? 1 : currentValue.number)) - currentValue.discount) + currentValue.taxiva) + previousValue
      ), 0,
    );
    if (total) {
      if (cart.filter((x) => x.discount > 0).length > 0) {
        return `$${roundDecimalValue(total, 2).toLocaleString('en')}`;
      }
      return `$${roundDecimalValue(total).toLocaleString('en')}`;
    }
    return `$${roundDecimalValue(total).toLocaleString('en')}`;
  };

  const getTotalByProduct = (row) => {
    const total = appName === 'colnomina' && row.isEmployee
      ? ((row.base) - row.discount) + row.taxiva
      : ((row.base * row.number) - row.discount) + row.taxiva;
    if (total) {
      if (row.discount > 0) {
        return `$${roundDecimalValue(total, 2).toLocaleString('en')}`;
      }
      return `$${roundDecimalValue(total).toLocaleString('en')}`;
    }
    return `$${roundDecimalValue(total).toLocaleString('en')}`;
  };

  /**
   * Metodo loadSinglePackage
   * @param {packageCode} packageCode parametro codigo paquete
   */
  const loadSingleBasePackage = (newPackageCode) => {
    // Cargo lo q este en el localStorage agregados
    let cartAddedStorage = null;
    const stringCartAddedEncrypt = getLocalStorageEncript('cartAdded');
    // Adicionar un paquete base empleados y documento solo aplica para colnomina
    if (stringCartAddedEncrypt !== undefined && appName === 'colnomina') {
      cartAddedStorage = JSON.parse(stringCartAddedEncrypt);
      const newPackageToAdd = getCartPackage(newPackageCode);
      const isDocument = getServiceFeatureCodeBool(newPackageToAdd, 'document');
      if (isDocument) {
        cartAddedStorage.forEach((item) => {
          const packageToAddAux = getCartPackage(item.packageCode);
          const isDocumentAux = getServiceFeatureCodeBool(packageToAddAux, 'document');
          if (isDocumentAux && newPackageCode !== item.packageCode) {
            cartAddedStorage.splice(
              cartAddedStorage.findIndex((x) => x.packageCode === item.packageCode),
              1,
            );
          }
        });
      }
      addLocalStorageEncript(JSON.stringify(cartAddedStorage), 'cartAdded');
      return cartAddedStorage;
    }
    // Adicionar un paquete base persona o empresa solo aplica para bemyself
    if (stringCartAddedEncrypt !== undefined && appName === 'bemyself') {
      cartAddedStorage = JSON.parse(stringCartAddedEncrypt);
      cartAddedStorage.forEach((item) => {
        if (newPackageCode !== item.packageCode) {
          cartAddedStorage.splice(
            cartAddedStorage.findIndex((x) => x.packageCode === item.packageCode),
            1,
          );
        }
      });
      addLocalStorageEncript(JSON.stringify(cartAddedStorage), 'cartAdded');
      return cartAddedStorage;
    }
    return cartAddExt;
  };

  const loadCartCrosscutting = () => {
    let cartAddValidated = [];
    if (idParam) { cartAddValidated = loadSingleBasePackage(idParam); } else { cartAddValidated = cartAddExt; }
    cartAddValidated.forEach((row) => {
      const packageToAdd = getCartPackage(row.packageCode);
      if (packageToAdd !== undefined) {
        const isEmployee = getServiceFeatureCodeBool(packageToAdd, 'employee');
        if (packageToAdd.packageType.packageTypeCode === 'BAS' && isEmployee) {
          const priceLicense = getPriceLicenseByQuantity(EMPLOYEE_TYPE, row.number);
          const {
            applyOffer, totalOffer, offerTypeCode, price,
          } = priceLicense;
          packageToAdd.packagePrice = price;
          packageToAdd.packageUnitPrice = price;
          packageToAdd.packagePriceWithDiscount = CalcPriceWithDiscountPackage(price, totalOffer, offerTypeCode);
          packageToAdd.discount = applyOffer ? CalcDiscountPackage(price, totalOffer, offerTypeCode) : 0;
          packageToAdd.quantity = row.number;
          addToCart(packageToAdd, enqueueSnackbar);
        } else {
          const qxPacks = row.number;
          // eslint-disable-next-line no-plusplus
          for (let i = 1; i <= qxPacks; i++) {
            packageToAdd.discount = packageToAdd.applyOffer ? CalcDiscountPackage(packageToAdd.packagePrice, packageToAdd.totalOffer, packageToAdd.offerTypeCode) : 0;
            addToCart(packageToAdd, enqueueSnackbar);
          }
        }
      } else {
        console.group('Error obteniendo por packageCode');
        console.info(`No se encontro el package code ${row.packageCode}, revise los codigos referenciados`);
        console.groupEnd();
      }
    });
  };

  const setFeaturesEmployee = (featureEmployee, priceLicense) => {
    featureEmployee.forEach((feature) => {
      if (feature.serviceFeature.serviceFeatureCode === 'COLNOMINA_EMPLEADO') {
        feature.quantity = priceLicense.quantity;
      } else if (feature.serviceFeature.serviceFeatureCode === 'COLNOMINA_USUARIO') {
        feature.quantity = priceLicense.quantityUsers;
      }
    });
  };

  const saveCartReducerToLocalStore = () => {
    addLocalStorageEncript(JSON.stringify(cart), 'cart');
  };

  const getAbsolutePath = () => {
    const loc = window.location;
    const pathName = loc.pathname;
    let fullPath = loc.href.substring(0, loc.href.length - ((loc.pathname + loc.search + loc.hash).length - pathName.length));

    const index = fullPath.indexOf('viewCartModal');
    if (index >= 0) {
      fullPath = fullPath.substring(0, index);
    }

    return `${fullPath}`;
  };

  const updateCartAddedLocal = (packageCode, quantity) => {
    let cartAddedStorage = null;
    const stringCartAddedEncrypt = getLocalStorageEncript('cartAdded');
    if (stringCartAddedEncrypt !== undefined) { cartAddedStorage = JSON.parse(stringCartAddedEncrypt); }

    const cartUpdate = cartAddedStorage.find((item) => item.packageCode === packageCode);
    cartUpdate.number = quantity;

    cartAddedStorage.splice(
      cartAddedStorage.findIndex((item) => item.packageCode === packageCode),
      1,
    );
    if (cartAddedStorage.length === 0) cartAddedStorage = [];
    cartAddedStorage.push(cartUpdate);
    addLocalStorageEncript(JSON.stringify(cartAddedStorage), 'cartAdded');
  };

  function editPriceItem(value, row, pricebyQuantity) {
    const { id, number } = row;
    const cartItem = cart.find((item) => item.packageId === id);
    if (pricebyQuantity) {
      const {
        applyOffer, totalOffer, offerTypeCode, price,
      } = pricebyQuantity;
      cartItem.packagePrice = price;
      cartItem.packageUnitPrice = price;
      cartItem.packagePriceWithDiscount = CalcPriceWithDiscountPackage(price, totalOffer, offerTypeCode);
      cartItem.discount = applyOffer ? CalcDiscountPackage(price, totalOffer, offerTypeCode) : 0;
      cartItem.quantity = value;
      UpdateQuantityCart(cartItem);
      updateCartAddedLocal(cartItem.packageCode, value);
    } else if (value > number) addToCart(cartItem, enqueueSnackbar);
    else deleteOfCart(cartItem.packageId, false);
  }

  const handleClickPurchase = () => {
    if (!userInfo) {
      const countBasePackage = packageBase?.filter((a) => cartClean?.some((b) => a.packageId === b.id)).length;

      if (countBasePackage === 0) {
        enqueueSnackbar(
          'Para continuar la compra, debes primero seleccionar un paquete base.',
          {
            variant: 'warning',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          },
        );
        return;
      }
      saveCartReducerToLocalStore();
      if (source === 'licenseWeb') {
        onClose();
        history.push('/registerPurchaseOrder');
      } else {
        window.parent.location.href = `${getAbsolutePath()}RegisterPurchaseExt`;
      }
    } else {
      const customerRequest = {
        DocumentType: userInfo.customer_type,
        DocumentNumber: userInfo.customer_value,
        ServiceName: appName,
      };

      getPackageBaseCustomer(
        customerRequest,
        setShowLoading,
        onClose,
        enqueueSnackbar,
        history,
      );
    }
  };

  const disabledFree = (row) => row.price === 0;

  function deleteItem(value, row, deleteItemCart) {
    const { id, number } = row;
    const cartAux = localCart || cart;
    const cartItem = cartAux.find((item) => item.packageId === id);
    const isDocument = getServiceFeatureCodeBool(cartItem, 'document');
    if (isDocument && appName === 'colnomina' && cartItem.packageType.packageTypeCode === 'BAS') {
      cartItem.quantity = 1;
    }
    if (value > number) {
      if (!isDocument) {
        addToCart(cartItem, enqueueSnackbar);
      }
      saveCartReducerToLocalStore();
      updateCartAddedLocal(cartItem.packageCode, isDocument ? 1 : value);
    } else {
      deleteOfCart(cartItem.packageId, false);
      saveCartReducerToLocalStore();

      // Eliminar del localStorage
      const stringCartAddedEncrypt = getLocalStorageEncript('cartAdded');
      if (stringCartAddedEncrypt !== undefined) {
        const cartAddBefore = JSON.parse(stringCartAddedEncrypt);
        const cartUpdateBefore = cartAddBefore.find((item) => item.packageCode === cartItem.packageCode);
        if (cartUpdateBefore) {
          cartUpdateBefore.number -= 1;
        }
        cartAddBefore.splice(
          cartAddBefore.findIndex((item) => item.packageCode === cartItem.packageCode),
          1,
        );
        if (cartUpdateBefore?.number > 0 && (appName !== 'colnomina' || !deleteItemCart)) {
          cartAddBefore.push(cartUpdateBefore);
        }
        addLocalStorageEncript(JSON.stringify(cartAddBefore), 'cartAdded');
        if (cartAddBefore.length === 0) {
          localStorage.removeItem('promoCode');
        }
      }
    }
    if (localCart.length === 0 || cart.length === 0) {
      clearLocalStorageStore();
    }
  }

  function showInfoItem(row) {
    const { id } = row;
    const cartItem = localCart.find((item) => item.packageId === id);
    const packageToAdd = getCartPackage(cartItem.packageCode);
    const isEmployee = getServiceFeatureCodeBool(packageToAdd, 'employee');
    if (packageToAdd.packageType.packageTypeCode === 'BAS' && isEmployee) {
      const priceLicense = getPriceLicenseByQuantity(EMPLOYEE_TYPE, cartItem.quantity);
      setFeaturesEmployee(cartItem.itemsPackageFeatures, priceLicense);
    }
    setFeaturesPackage(cartItem.itemsPackageFeatures);
  }
  const handleQuantity = (e, row, type, val, flag) => {
    let value = 0;

    if (flag === 1 || flag === 2) {
      value = val;
    } else if (!getAddMultipleItemsToCart(appName) && type !== 'EMPLOYEE') {
      value = 1;
    } else {
      value = e.target.value;
    }
    if (type) {
      const price = getPriceLicenseByQuantity(type, value);
      editPriceItem(value, row, price);
    } else if (getAddMultipleItemsToCart(appName)) {
      // elimina item anterior en cart
      deleteItem(value, row, false);
    }
    if (localPromoCode) {
      const data = {
        PromotionCode: localPromoCode.promoCode,
      };
      applyPromotionCode(data, setShowLoading, enqueueSnackbar, false);
    }
    setRefreshPage(true);
    localStorage.setItem('refreshPage', true);
  };

  const getAddMultipleItemsToCart = (appName) => {
    switch (appName) {
      case 'firmaya':
        return false;
      case 'colfactura':
        return true;
      case 'firmamail':
        return true;
      case 'colnomina':
        return false;
      case 'superguarda':
        return false;
      case 'bemyself':
        return true;
      default:
        return true;
    }
  };

  const handleRedirectToHome = () => {
    onClose();
    if (appName === 'colnomina' || appName === 'firmaya' || appName === 'superguarda') {
      if (applicationInfo?.urlLandingPage) {
        window.location.href = applicationInfo?.urlLandingPage;
      }
    } else {
      history.push('/');
    }
  };

  const handleDelete = (e, row) => {
    deleteItem(0, row, true);
    setRefreshPage(true);
    localStorage.setItem('refreshPage', true);
  };

  const handleApplyOfert = (data) => {
    applyPromotionCode(data, setShowLoading, enqueueSnackbar);
    reset();
  };

  const handleShowElement = (id) => {
    setIsShownId(id);
  };

  const handlePopoverOpen = (e, row) => {
    setAnchorEl(e.currentTarget);
    showInfoItem(row);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const unique = cartClean.length === 9999;
  const open = Boolean(anchorEl);

  if ((loading) || (refreshPage === true && localStorage.getItem('refreshPage') === 'true')) {
    if (source !== 'licenseWeb') {
      return <Loading />;
    }
  }

  return (
    <>
      <div className={classes.root}>
        <Dialog
          fullScreen={!fullWidth}
          fullWidth={fullWidth}
          maxWidth="md"
          open={isOpen}
          classes={{ paper: isXs && classes.backgroundDialogResponsive }}
        >
          <DialogTitle className={classes.dialogTitle}>
            <Grid item xs={12}>
              <Typography className={classes.titleModalCart}>
                {unique ? cart[0].packageName : 'Carrito de compras'}
              </Typography>
              {
                source === 'licenseWeb' && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<AddCircleOutlineTwoToneIcon />}
                    className={classes.btnAddProducts}
                    onClick={() => {
                      handleRedirectToHome();
                    }}
                  >
                    Agregar productos
                  </Button>
                )
              }
            </Grid>
            {closePopup && (
              <IconButton
                aria-label="close"
                color="primary"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon className={classes.tamIcon} />
              </IconButton>
            )}
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Grid container>
              {unique && !isXs && (
                <Grid item xs={4}>
                  <SliderCard
                    card={cart[0]}
                    showActions={false}
                    showHeader={false}
                    isbgColor
                    colorBgSlider={theme.home.cart.slider.backgroundColor}
                  />
                </Grid>
              )}
              <Grid item xs={unique && !isXs ? 8 : 12}>
                <form>
                  <Box component="div" overflow="visible" className={`${classes.tableItemsCart}`}>
                    {
                      isXs
                        ? (
                          <CardModalExt
                            cart={cart}
                            cartClean={cartClean}
                            open={open}
                            handleShowElement={handleShowElement}
                            handlePopoverOpen={handlePopoverOpen}
                            handlePopoverClose={handlePopoverClose}
                            handleQuantity={handleQuantity}
                            quantityLicenseLocalEmployee={quantityLicenseLocalEmployee}
                            disabledFree={disabledFree}
                            roundDecimalValue={roundDecimalValue}
                            getTotalByProduct={getTotalByProduct}
                            isShownId={isShownId}
                            handleDelete={handleDelete}
                            EMPLOYEE_TYPE={EMPLOYEE_TYPE}
                          />
                        ) : (
                          <TableModalExt
                            cart={cart}
                            cartClean={cartClean}
                            open={open}
                            handleShowElement={handleShowElement}
                            handlePopoverOpen={handlePopoverOpen}
                            handlePopoverClose={handlePopoverClose}
                            handleQuantity={handleQuantity}
                            quantityLicenseLocalEmployee={quantityLicenseLocalEmployee}
                            disabledFree={disabledFree}
                            roundDecimalValue={roundDecimalValue}
                            getTotalByProduct={getTotalByProduct}
                            isShownId={isShownId}
                            handleDelete={handleDelete}
                            EMPLOYEE_TYPE={EMPLOYEE_TYPE}
                            paymentCashApply={parameterApp && parameterApp.paymentCashApply}
                          />
                        )
                    }
                  </Box>
                </form>
                {
                  parameterApp && parameterApp.integrationServiceApply && (
                    <Grid item xs={12}>
                      <Typography className={classes.observationText}>
                        {'Estos valores no incluyen integración. Para cotizar este servicio '}
                        <a href={URL_CONTACT_COLNOMINA} className={classes.linkContact} target="_blank">haz clic aquí</a>
                      </Typography>
                    </Grid>
                  )
                }
                <Divider className={classes.divider} variant="fullWidth" />
                <Grid container>
                  <Grid item xs={12} sm={6} lg={8}>
                    {
                      parameterApp && parameterApp.promotionCodeApply && (
                        <>
                          <form
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit(handleApplyOfert)}
                            className={isXs ? classes.promoForm : classes.promoFormNoResponsive}
                          >
                            <FormControl>
                              <Controller
                                as={(
                                  <TextField
                                    label="Código promocional"
                                    size="small"
                                    variant="outlined"
                                    inputProps={{
                                      maxLength: 20,
                                      onChange: (e) => {
                                        if (e.target.value !== '') {
                                          setIsDisabled(false);
                                        } else {
                                          setIsDisabled(true);
                                        }
                                      },
                                    }}
                                    className={classes.textPromotional}
                                    FormHelperTextProps={{ style: { marginRight: 0 } }}
                                    helperText={
                                      errors.PromotionCode
                                        ? errors.PromotionCode.message
                                        : null
                                    }
                                  />
                                )}
                                name="PromotionCode"
                                control={control}
                                rules={Rules.required}
                                defaultValue=""
                              />
                            </FormControl>
                            <Button
                              variant="contained"
                              type="submit"
                              style={{ marginTop: 2 }}
                              disabled={isDisabled}
                              className={!isXs ? classes.btnPromotional : classes.btnPromotionalResponsive}
                            >
                              {showloading ? 'Cargando' : 'Aplicar'}
                              {showloading && <CircularProgress color="secondary" size={25} />}
                            </Button>
                          </form>
                          <Typography className={!isXs ? classes.infoDescountText : classes.infoDescountTextResponsive}>
                            <span>*El valor del descuento aplica antes de IVA.</span>
                          </Typography>
                        </>
                      )
                    }
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Box className={!isXs ? classes.totalWrapper : classes.totalWrapperResponsive}>
                      <Typography className={classes.totalText}>
                        <>
                          <span style={{ fontWeight: 800 }}>Total: </span>
                          <span style={{ fontWeight: 800 }}>
                            {showloading ? 'Procesando...' : `${getSubtotal(cartClean)}`}
                            {showloading && (
                              <CircularProgress color="secondary" size={25} />
                            )}
                          </span>
                        </>
                      </Typography>
                      <Typography className={classes.taxInclude}>
                        Impuesto incluido
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleClickPurchase}
                        className={!isXs ? classes.btnContinue : classes.btnContinueResponsive}
                        disabled={!cartClean.length}
                      >
                        Continuar
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        {/* Popover Detalles de Paquete */}
        {parameterApp && parameterApp.planFeaturesview && (
          <DetailPackage featuresPackage={featuresPackage} anchorEl={anchorEl} />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ home, general }) => ({
  isOpen: home.isCartOpenExt,
  cart: home.cart,
  promoCode: home.promoCode,
  packages: home.packages,
  appName: general.appName,
  pricesLicense: home.pricesLicense,
  quantityLicense: home.quantityLicense,
  applicationInfo: general.applicationInfo,
  loading: home.loading,
  parameterApp: general.parameterApp,
});

const mapDispatchToProps = {
  setIsCartOpen: setStatusCart,
  onClose: () => setStatusCart(false),
  addToCart: addToCartAction,
  deleteOfCart: deleteOfCartAction,
  applyPromotionCode: applyPromotionCodeAction,
  getPackageBaseCustomer: getPackageBaseCustomerAction,
  getQuantityByType: getQuantityByTypeAction,
  getPriceLicenseByQuantity: getPriceLicenseByQuantityAction,
  getPackages: getPackagesAction,
  UpdateQuantityCart: UpdateQuantityCartAction,
  getPriceByLicenseType: getPriceByLicenseTypeAction,
  addPricesLicense: addPricesLicenseAction,
  addQuantityLicense: addQuantityLicenseAction,
  UpdateQuantityPriceCart: UpdateQuantityPriceCartAction,
  getParmeterConfigAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CartModalExt),
);
