/* eslint-disable no-prototype-builtins, react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { ReactComponent as AddShoppingCartIcon } from '../../../assets/img/iconos/license/AddToShoppingCartIcon.svg';

import { deleteOfCartAction } from '../../../actions/home.action';
import { CleanCart } from '../../../helpers/cartUtils';
import { roundDecimalValue } from '../../../helpers/generalUtils';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  card: {
    ...theme.home.cart.summaryCart,
    width: 400,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    justifyContent: 'space-between',
  },
  noItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 24,
    '& .MuiTypography-body1': {
      ...theme.home.cart.noItems,
    },
  },
  cardContent: {
    padding: '15px 15px 0',
  },
  cardHeader: {
    backgroundRepeat: 'no-repeat',
    height: '140px',
    padding: '11px 20px 0',
    color: 'white',
  },
  cardList: {
    padding: 15,
    fontSize: '16px',
    letterSpacing: '0px',
    lineheight: 1,
  },
  addToCartButton: {
    width: 37,
    minWidth: 0,
  },
  subTotalText: {
    ...theme.home.cart.subtotalText,
  },
  btnGoToCart: {
    ...theme.home.cart.btnGoToCart,
    '& .MuiButton-label': {
      fontWeight: 900,
      fontSize: '16px',
    },
  },
  btnRemoveFromCart: {
    ...theme.home.cart.btnRemoveFromCart,
  },
  iconShoppingCart: {
    ...theme.home.cart.iconShoppingCart,
  },
  itemListTextStyle: {
    ...theme.home.cart.itemListTextStyle,
  },
  divider: {
    '& .MuiListItem-divider': {
      borderBottom: '1px solid #F6F7F97A',
    },
  },
}));

const getSubtotal = (cart) => {
  const total = cart.reduce(
    (previousValue, currentValue) => (
      (((currentValue.base * currentValue.number) - currentValue.discount) + currentValue.taxiva) + previousValue
    ), 0,
  );
  if (total) {
    if (cart.filter((x) => x.discount > 0).length > 0) {
      return `$${roundDecimalValue(total, 2).toLocaleString('en')}`;
    }
    return `$${roundDecimalValue(total).toLocaleString('en')}`;
  }
  return `$${roundDecimalValue(total).toLocaleString('en')}`;
};

const getTotalByProduct = (row) => {
  const total = ((row.base * row.number) - row.discount) + row.taxiva;
  if (total) {
    if (row.discount > 0) {
      return `$${roundDecimalValue(total, 2).toLocaleString('en')}`;
    }
    return `$${roundDecimalValue(total).toLocaleString('en')}`;
  }
  return `$${roundDecimalValue(total).toLocaleString('en')}`;
};

const CartMenu = (props) => {
  const {
    element, setElement, cart, promoCode, openCartModal, deleteOfCart, history, isClearAllCart,
  } = props;

  const [isShownId, setIsShownId] = useState(0);
  const cartClean = CleanCart(cart, promoCode);
  const classes = useStyles();

  useEffect(() => {
    if (isClearAllCart) {
      setElement(null);
      history.push('/');
    }
  }, [isClearAllCart]);

  const handleDelete = (item) => {
    deleteOfCart(item.id, true);
  };

  const handleClick = () => {
    setElement(null);
    openCartModal();
  };

  const handleShowElement = (id) => {
    setIsShownId(id);
  };

  return (
    <Popover
      id={element ? 'cartMenu-popover' : undefined}
      open={Boolean(element)}
      anchorEl={element}
      onClose={() => setElement(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: { borderRadius: 16 },
      }}
    >
      <Card className={classes.card}>
        {
          cart.length
            ? (
              <>
                <CardContent className={classes.cardContent}>
                  <List disablePadding className={classes.divider}>
                    { cartClean.map((item) => (
                      <ListItem
                        key={item.id}
                        divider
                        dense
                        disableGutters
                        onMouseEnter={() => handleShowElement(item.id)}
                        onMouseLeave={() => handleShowElement(0)}
                      >
                        <ListItemText
                          primary={item.name}
                          primaryTypographyProps={{ style: { fontSize: 11, fontWeight: 700 } }}
                          className={classes.itemListTextStyle}
                        />
                        <ListItemSecondaryAction
                          onMouseEnter={() => handleShowElement(item.id)}
                          onMouseLeave={() => handleShowElement(0)}
                        >
                          {isShownId === item.id ? (
                            <Tooltip title="Eliminar">
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleDelete(item)}
                              >
                                <DeleteIcon
                                  color="secondary"
                                  className={classes.btnRemoveFromCart}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Typography style={{ fontSize: 11 }} className={classes.itemListTextStyle}>
                              {`${getTotalByProduct(item)} x ${item.number}`}
                            </Typography>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                    <ListItem
                      key="cartMenu-popover-subtotal"
                      disableGutters
                    >
                      <ListItemText
                        primary="Subtotal"
                        primaryTypographyProps={{ style: { fontWeight: 900 } }}
                        className={classes.subTotalText}
                      />
                      <ListItemSecondaryAction>
                        <Typography
                          style={{ fontWeight: 900 }}
                          className={classes.subTotalText}
                        >
                          {getSubtotal(cartClean)}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </CardContent>
                <CardActions style={{ justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btnGoToCart}
                    onClick={() => handleClick()}
                  >
                    Ir al carrito
                    <ShoppingCartIcon style={{ marginLeft: 5 }} />
                  </Button>
                </CardActions>
              </>
            )
            : (
              <CardContent className={classes.noItems}>
                <Typography style={{ marginBottom: 10, fontWeight: 500 }}>
                  ¡Tu carrito está vacío!
                </Typography>
                <AddShoppingCartIcon className={classes.iconShoppingCart} />
              </CardContent>
            )
        }
      </Card>
    </Popover>
  );
};

const mapStateToProps = ({ home }) => ({
  promoCode: home.promoCode,
  isClearAllCart: home.isClearAllCart,
});

const mapDispatchToProps = {
  deleteOfCart: deleteOfCartAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartMenu);
