/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid, react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Rules } from '../../helpers/RHFRules';
import Loading from '../../components/crosscutting/Loading';
import AlertConfirm from '../../components/crosscutting/AlertConfirm';

import {
  savePurchaseAction, getCustomerInformationAction,
  getNameByTypeDocAndDocAction, validityChangeCustomerAction, setMessageAlertAction,
  resetFormRegisterAction,
} from '../../actions/purchaseLicense.action';
import { setShowCartAction } from '../../actions/home.action';
import { getPurchaseOrderParametersAction, getTokenExternalSourceAction } from '../../actions/general.action';
import { CleanCart } from '../../helpers/cartUtils';
import { calculateCheckDigit, redirectUrlAppName, capitalizeFirstLetter } from '../../helpers/generalUtils';
import { setGeoCode } from '../../helpers/googleMapsUtils';

import CustomInput from '../../components/form/CustomInput';
import CustomCheck from '../../components/form/CustomCheck';
import CustomSelect from '../../components/form/CustomSelect';
import CustomAutocompleteSelect from '../../components/form/Autocomplete/CustomAutocompleteSelect';
import AutocompleteGoogleMaps from '../../components/form/AutocompeteGoogleMaps/CustomAutocompleteGoogleMaps';
import CheckEmail from '../../assets/img/activatePlan/email-logo.svg';
import { getDocumentTypes } from '../../helpers/documentsTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '10%',
    marginBottom: '10%',
    padding: '20px',
    borderRadius: '14px',
    ...theme.registerForm.masterContainer,
  },
  titleForm: {
    paddingTop: '15px',
    paddingBottom: '25px',
    paddingRight: '50px',
    fontWeight: 'bold',
    fontSize: 26,
    color: theme.palette.primary.main,
  },
  divider: {
    marginBottom: '10px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: theme.palette.primary.main,
    ...theme.registerForm.containerForm,
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  margin: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  contentTextPrimary: {
    color: theme.palette.primary.main,
  },
  contentTextSecondary: {
    color: theme.palette.secondary.main,
    fontSize: 15,
  },
  policyAndTerms: {
    ...theme.registerForm.activatePlan.policyAndTerms,
    fontSize: 14,
  },
  contentInfoAddress: {
    color: '#77CAED',
  },
  submit: {
    textAlign: 'center',
    padding: 10,
  },
  btnSubmit: {
    marginLeft: 10,
    fontSize: 16,
    width: 250,
    borderRadius: 10,
    fontWeight: 100,
    '&:disabled': {
      ...theme.registerForm.activatePlan.buttonActivate,
      opacity: 0.4,
      fontWeight: 100,
    },
  },
  panelSummary: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    maxHeight: '50px',
    borderRadius: 4,
  },
  expanded: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    borderRadius: 4,
    borderColor: theme.palette.secondary.main,
    '&$expanded': {
      minHeight: '0px',
      maxHeight: '50px',
    },
  },
  infoText: {
    color: theme.palette.secondary.main,
    marginTop: -23,
    fontSize: theme.typography.pxToRem(16),
    position: 'absolute',
  },
  legalTerms: {
    padding: '20px',
  },
  legalTermsPolicy: {
    marginTop: '7px',
    marginLeft: '-40px',
  },
  formErrors: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(13),
  },
  buttonProgress: {
    color: theme.palette.error.main,
    top: '10px',
    marginLeft: '10px',
    position: 'relative',
  },
  expansionPanelTitle: {
    color: '#212121',
    fontWeight: 'bold',
    fontSize: 16,
  },
  expansionPanelIcon: {
    color: '#212121',
  },
  btnCashPayment: {
    display: 'none',
  },
  alertActivation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleActivated: {
    paddingTop: '60px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  logoActivated: {
    paddingTop: '50px',
    paddingBottom: '30px',
  },
  messageActivated: {
    fontSize: 17,
    textAlign: 'center',
  },
  secondaryMessageAlert: {
    textAlign: 'center',
    color: '#7b7b7b',
    paddingTop: 15,
    paddingBottom: 20,
  },
  timeActivation: {
    fontSize: 17,
    textAlign: 'center',
    paddingBottom: '50px',
  },
  buyNow: {
    textDecoration: 'underline',
    fontSize: 14,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  termsAndConditions: {
    fontSize: 14,
  }
}));

const personType = [
  { value: 'J', label: 'Persona Juridica' },
  { value: 'N', label: 'Persona Natural' },
];

const ActivatePlan = (props) => {
  const classes = useStyles();
  const {
    loading, loadingGral, getError, userInfo, appName,
    savePurchase, getPurchaseOrderParameters,
    documentTypes, statesAndCities, applicationInfo,
    serviceInfo, typeLoad, getCustomerInformation,
    cart, history, getNameByTypeDocAndDoc, setShowCart,
    getTokenExternalSource, validityChangeCustomer,
    validityChangeCustomerAct, referenceUuidPurchaseOrder, code,
    showMessageAlert, setMsgAlert, resetFormRegister, citiesServGoogleAssistant,
  } = props;


  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    handleSubmit, errors, control, setValue, getValues, watch, setError, reset, formState,
  } = useForm({
    mode: 'onChange',
  });

  const watchPersonTypeSer = watch('personTypeService');
  const watchDocTypeSer = watch('documentTypeIdService');
  const watchDocSer = watch('documentNumberService');
  const watchLegalNameSer = watch('legalNameService');
  const watchFirstNameSer = watch('firstNameService');
  const watchLastNameService = watch('lastNameService');
  const watchCellphoneNumberSer = watch('cellphoneNumberService');
  const watchEmailSer = watch('emailService');
  const watchFulladdressSer = watch('fulladdressService');
  const watchDepartmentIdSer = watch('departmentIdService');
  const watchCityIdSer = watch('cityIdService');
  const watchLegalDocTypeSer = watch('legalDocumentTypeIdService');
  const watchDocLeg = watch('legalDocumentNumberService');
  const watchLegalFirstNameSer = watch('legalFirstNameService');
  const watchLegalLastNameSer = watch('legalLastNameService');
  const watchLegalTermsCheck = watch('legalTermsCheck');
  const [departaments, setDepartaments] = useState([]);
  const [citiesService, setCitiesService] = useState([]);
  const [dataForm, setFormData] = React.useState({});
  const [personTypeService, setPersonTypeService] = React.useState('J');
  const [checkService, setCheckService] = useState(Boolean(Object.keys(serviceInfo).lenght));
  const [paymentMethod, setPaymentMethod] = React.useState('');
  const [openAlertInfo, setOpenAlertInfo] = useState(false);
  const [typeAlert, setTypeAlert] = useState('');
  const [titleAlert, setTitleAlert] = useState('');
  const [messageAlert, setMessageAlert] = useState('');
  const [textButtonAccept, setTextButtonAccept] = useState('');
  const [isOnlyMessage, setIsOnlyMessage] = useState(false);
  const [subMensaje, setSubMensaje] = useState('');
  const [showCloseAlert, setShowCloseAlert] = useState(false);
  const [showButtonCancel, setShowButtonCancel] = useState(false);
  const [loadingRues, setLoadingRues] = React.useState(false);
  const [valueDepartamentService, setValueDepartamentService] = React.useState(null);
  const [valueCityService, setValueCityService] = React.useState(null);
  const [geoCodeService, setGeoCodeService] = React.useState({ lat: null, lng: null });
  const [addressMapsService, setAddressMapsService] = React.useState(null);
  const [inputAddressServiceValue, setInputAddressServiceValue] = React.useState('');
  const [optionsAddressService, setOptionsAddressService] = React.useState([]);
  const [disabledToSave, setDisabledToSave] = React.useState(false);
  const [disabledSubmit, setDisabledSubmit] = React.useState(false);
  const [loadingView, setLoadingView] = React.useState(false);

  /**
   * Metodo ir a Url Security
   */
  const handleGoToSecurity = () => {
    window.location.href = applicationInfo.urlSecutityLogin;
  };

  /**
   * Metodo ir a landing Page
   */
  const handleGoToLandingPage = () => {
    if (applicationInfo?.urlLandingPage) {
      window.location.href = applicationInfo.urlLandingPage;
    }
  };

  // ---------------------------------------------
  // method - carga de datos de cliente
  // ---------------------------------------------
  const loadCustomerData = (type) => {
    if (userInfo) {
      const data = {
        documentType: userInfo.customer_type,
        documentNumber: userInfo.customer_value,
      };
      getCustomerInformation(data, type);
    }
  };

  useEffect(() => {
    if (statesAndCities) {
      setDepartaments(statesAndCities.itemsStatesList.sort((a, b) => {
        const x = a.text < b.text ? -1 : 1;
        return x;
      }));
      if (userInfo) {
        setCheckService(true);
        loadCustomerData();
      }
    }
  }, [statesAndCities]);

  useEffect(() => {
    if (validityChangeCustomer === false) {
      setMsgAlert({
        category: 'changeCustomer',
        typeMsg: 'Info',
        tittleMsg: '',
        message: 'Recuerde que si modifica estos datos la licencia quedara registrada con esta información y no con la del formulario de facturación',
        showCancelButton: true,
      });
    }
  }, [validityChangeCustomer]);

  /**
   * useEffect validacion cart
   */
  useEffect(() => {
    if (cart.length > 0) {
      setDisabledToSave(false);
    } else {
      setDisabledToSave(true);
    }
  }, [cart]);

  /**
   * UseEfect estado de formulario formState
   */
  useEffect(() => {
    const errorCount = Object.getOwnPropertyNames(formState.errors);
    if (errorCount.length > 0) setDisabledSubmit(true);
    if (watchPersonTypeSer === 'J') {
      if (!watchDocTypeSer || !watchDocSer || !watchLegalNameSer || !watchCellphoneNumberSer
        || !watchEmailSer || !watchFulladdressSer || !watchDepartmentIdSer || !watchCityIdSer
        || !watchLegalDocTypeSer || !watchDocLeg || !watchLegalFirstNameSer || !watchLegalLastNameSer
        || !watchLegalTermsCheck) {
        setDisabledSubmit(true);
      } else if (errorCount.length === 0) {
        setDisabledSubmit(false);
      }
    } else if (!watchDocTypeSer || !watchDocSer || !watchFirstNameSer || !watchLastNameService
      || !watchCellphoneNumberSer || !watchEmailSer || !watchFulladdressSer || !watchDepartmentIdSer
      || !watchCityIdSer || !watchLegalTermsCheck) {
      setDisabledSubmit(true);
    } else if (errorCount.length === 0) {
      setDisabledSubmit(false);
    }
  }, [formState]);

  // ---------------------------------------------
  // method - Remover espacios texto
  // ---------------------------------------------
  const removeSpaces = (nameDoc) => {
    let docNumAux = getValues(nameDoc);
    if (docNumAux) {
      if (docNumAux.match(/\s/g)) {
        docNumAux = docNumAux.replace(/\s/g, '');
      }
      setValue(nameDoc, docNumAux);
    }
  };

  // ---------------------------------------------
  // method - Obtiene digito de verificación
  // ---------------------------------------------
  const getCheckDigit = (dv, nameDoc, typeDoc) => {
    const numero = getValues(nameDoc);
    if (typeDoc === 4 && numero && numero.length > 5) {
      const checkDigit = calculateCheckDigit(numero);
      if (checkDigit || checkDigit === 0) {
        setValue(dv, checkDigit);
      }
    } else {
      setValue(dv, '');
    }
  };

  useEffect(() => {
    removeSpaces('documentNumberService');
  }, [watchDocSer]);

  useEffect(() => {
    removeSpaces('legalDocumentNumberService');
  }, [watchDocLeg]);

  /**
   * Set info in specific camps these info is obtained from back petition
   * @param {*} name Billin o Sellgin
   * @param {*} personType N Natural, J Juridica
   * @param {*} response Object info to set
   */
  const setValuesFromResponse = (name, customerPersonType, response) => {
    if (customerPersonType === 'J') {
      setValue(`legalName${name}`, response.legalName);
    } else {
      setValue(`firstName${name}`, response.firstName);
      setValue(`lastName${name}`, response.lastName);
    }
  };

  // ---------------------------------------------
  // method - Obtiene datos de carga de rues clientes
  // ---------------------------------------------
  const getDefaulttNameByTypeAndDoc = async (customerPersonType, valueDocType, valueDoc, name) => {
    const doc = getValues(valueDoc);
    const docType = getValues(valueDocType);
    if (name === 'Service') {
      getCheckDigit('dvService', 'documentNumberService', watchDocTypeSer);
    }
    if (doc && (docType === 4 || docType === 1)) {
      setLoadingRues(true);
      const response = await getNameByTypeDocAndDoc(customerPersonType, docType, doc, appName);
      if (response) {
        setValuesFromResponse(name, customerPersonType, response);
      }
      setLoadingRues(false);
    }
  };

  // ---------------------------------------------
  // effect - Obtiene datos de carga inicial de formulario
  // ---------------------------------------------
  useEffect(() => {
    if (cart.length) {
      resetFormRegister();
      getTokenExternalSource();
      getPurchaseOrderParameters();
    } else {
      setShowCart(false);
    }
  }, []);

  // ---------------------------------------------
  // method - Setear direccion facturacion / Servicio
  // ---------------------------------------------
  const setAddressCustomer = (valueAddress, setOptionsMaps, setAddressValue, combo, geoCode) => {
    if (valueAddress) {
      const optionsAddress = [];

      optionsAddress.push({
        description: valueAddress,
        structured_formatting: {
          main_text: valueAddress,
          main_text_matched_substrings: [
            {
              length: 0,
              offset: 0,
            },
          ],
          secondary_text: '',
        },
      });

      setOptionsMaps(optionsAddress);
      if (optionsAddress.length > 0) {
        setAddressValue(optionsAddress[0]);
        setValue(combo, optionsAddress[0]);
        setGeoCode(valueAddress, geoCode);
      }
    }
  };

  // ---------------------------------------------
  // method - Setear ciudad cliente servicio
  // ---------------------------------------------
  const setCityCustomerServiceId = (dptoId, dptoName, cityId, cityName, checkServ) => {
    setValueDepartamentService(null);
    setValueCityService(null);
    if (checkServ) {
      let dptoServ = {};
      if (dptoId > 0) {
        dptoServ = statesAndCities.itemsStatesList.find((d) => d.value === dptoId);
      } else {
        dptoServ = statesAndCities.itemsStatesList.find((d) => d.text === dptoName);
        // dptoBilling = statesAndCities.itemsStatesList.find((d) => dptoName.include(d.text));
      }

      if (dptoServ) {
        setValueDepartamentService(dptoServ);
        setValue('departmentIdService', dptoServ);
      }

      let newCities = {};
      if (dptoId > 0) {
        newCities = statesAndCities.statesList.find((d) => d.id === dptoId);
      } else {
        newCities = statesAndCities.statesList.find((d) => d.stateName === dptoName);
      }
      if (newCities) {
        setCitiesService(newCities.city);

        let cityBilling = {};
        if (cityId > 0) {
          cityBilling = newCities.city.find((d) => d.id === cityId);
        } else {
          cityBilling = newCities.city.find((d) => d.cityName === cityName);
        }

        if (cityBilling) {
          setValueCityService(cityBilling);
          setValue('departmentIdService', dptoServ.value);
          setValue('cityIdService', cityBilling.id);
        }
      }
    }
  };

  useEffect(() => {
    if (!typeLoad || typeLoad === 'Service') {
      setValue('personTypeService', checkService ? serviceInfo.personType : '');
      setPersonTypeService(serviceInfo.personType);
      const documentTypeService = (appName === 'colfactura' || appName === 'colnomina')
        ? serviceInfo.documentTypeColfacturaId
        : serviceInfo.documentTypeId;
      setValue('documentTypeIdService', checkService ? documentTypeService : '');
      setValue('documentNumberService', checkService ? serviceInfo.documentNumber : '');
      setValue('dvService', checkService ? serviceInfo.dv : '');
      setValue('legalNameService', checkService ? serviceInfo.legalName : '');
      setValue('firstNameService', checkService ? serviceInfo.firstName : '');
      setValue('lastNameService', checkService ? serviceInfo.lastName : '');
      setValue('cellphoneNumberService', checkService ? serviceInfo.cellphoneNumber : '');
      setValue('emailService', checkService ? serviceInfo.email : '');
      setValue('departmentIdService', checkService ? serviceInfo.departmentId : '');
      if (statesAndCities) {
        setCityCustomerServiceId(serviceInfo.departmentId, null, serviceInfo.cityId, null, checkService);
      }
      setValue('cityIdService', checkService ? serviceInfo.cityId : '');
      if (checkService) {
        setAddressCustomer(serviceInfo.fullAddress, setOptionsAddressService, setAddressMapsService, 'fulladdressService', setGeoCodeService);
      } else {
        setValue('fulladdressService', '');
        setOptionsAddressService([]);
        setAddressMapsService(null);
      }
      setValue('legalDocumentTypeIdService', checkService ? serviceInfo.legalDocumentTypeId : '');
      setValue('legalDocumentNumberService', checkService ? serviceInfo.legalDocumentNumber : '');
      setValue('legalFirstNameService', checkService ? serviceInfo.legalFirstName : '');
      setValue('legalLastNameService', checkService ? serviceInfo.legalLastName : '');
      setValue('additionalInfoService', checkService ? serviceInfo.addressAdditionalInformation : '');
    }
  }, [serviceInfo]);

  /**
   * Metodo mostrar error
   * @param {*} name nombre error
   * @param {*} msj mensaje de error
   */
  const showError = (name, msj) => {
    setError(name, {
      type: 'manual',
      message: msj,
    });
  };

  /**
   * method - Guardar formulario de orden de compra
   */
  const savePurchaseOrder = () => {
    const documentNumberService = getValues('documentNumberService');
    const documentNumberCustomer = serviceInfo.documentNumber;

    if (getValues('personTypeService') === 'J' && getValues('legalDocumentNumberService') === documentNumberService) {
      showError('legalDocumentNumberService', 'Nit y Número de Documento no deben ser iguales.');
    } else if (documentNumberCustomer && documentNumberCustomer !== documentNumberService) {
      validityChangeCustomerAct(documentNumberCustomer, documentNumberService);
    } else if (paymentMethod === 'EN_LINEA' || paymentMethod === 'SIN_PAGO') {
      savePurchase(dataForm, paymentMethod, appName, history, null, null, geoCodeService);
    } else {
      setMsgAlert({
        category: 'cashPaymentPurchase',
        typeMsg: 'Info',
        tittleMsg: 'Pago en efectivo',
        message: 'Para pagar en efectivo debes imprimir la orden de pedido que te vamos a enviar al correo y seguir las instrucciones que allí se especifican.',
        textButtonAcept: 'Enviar orden',
      });
    }
  };

  /**
   * Metodo resetear formulario por defecto
   */
  const resetForm = () => {
    setValue('fulladdressService', '');
    setOptionsAddressService([]);
    setAddressMapsService(null);
    setInputAddressServiceValue('');
    setValueDepartamentService(null);
    setValueCityService(null);
    setValue('departmentIdService', null);
    setValue('cityIdService', '');
    reset();
  };

  useEffect(() => {
    if (getError) {
      setOpenAlertInfo(true);
      setMsgAlert({
        category: 'errorRegisterPayment',
        typeMsg: 'Error',
        showCloseAlert: true,
        tittleMsg: getError.code === 'ER15' ? '' : '¡Lo sentimos!',
        message: getError.message
          ? getError.message
          : 'Se ha presentado un error al procesar la compra, por favor vuelve a intentarlo. Web',
        subMensaje: '¡Anímate! Adquiere tu licencia',
        textButtonAcept: 'Comprar Ahora',
      });
      // ToDo guardar log de error presentado
    }
  }, [getError]);

  useEffect(() => {
    if (showMessageAlert) {
      setTypeAlert(showMessageAlert.category);
      setTitleAlert(showMessageAlert.tittleMsg);
      setMessageAlert(showMessageAlert.message);
      setShowCloseAlert(showMessageAlert.showCloseAlert ? showMessageAlert.showCloseAlert : false);
      setShowButtonCancel(showMessageAlert.showCancelButton ? showMessageAlert.showCancelButton : false);
      setTextButtonAccept(showMessageAlert.textButtonAcept ? showMessageAlert.textButtonAcept : 'Aceptar');
      setIsOnlyMessage(showMessageAlert.isOnlyMessage);
      setSubMensaje(showMessageAlert.subMensaje);
      setOpenAlertInfo(true);

      const { category } = showMessageAlert;
      switch (category) {
        case 'activatePlan':
          resetForm();
          break;
        default:
          break;
      }
    }
  }, [showMessageAlert]);

  useEffect(() => {
    if (citiesServGoogleAssistant && citiesServGoogleAssistant.deptoName !== null) {
      setCityCustomerServiceId(0, citiesServGoogleAssistant.deptoName, 0, citiesServGoogleAssistant.cityName, true);
    } else if (addressMapsService && optionsAddressService.length > 3 && !checkService) {
      setValueDepartamentService(null);
      setValueCityService(null);
      setValue('departmentIdService', '');
      setValue('cityIdService', '');
    }
  }, [citiesServGoogleAssistant]);

  // ---------------------------------------------
  // method - setear tipo de persona
  // ---------------------------------------------
  const setPersonType = (event) => {
    const { value } = event.target;
    if (event.target.name === 'personTypeService') {
      setPersonTypeService(value);
      if (value === 'J') {
        setValue('documentTypeIdService', 4);
      }
      getDefaulttNameByTypeAndDoc(value, 'documentTypeIdService', 'documentNumberService', 'Service');
    }
    return value;
  };

  // ---------------------------------------------
  // event envio de datos de formulario
  // ---------------------------------------------
  const onSubmit = (formData) => {
    setFormData(formData);
    savePurchase(formData, paymentMethod, appName, history, null, null, geoCodeService, true);
  };

  // ---------------------------------------------
  // event handler cambio de estado cliente servicio
  // ---------------------------------------------
  const handleChangeStateCustomerServiceId = (idDepartament) => {
    setValueCityService(null);
    const newCities = statesAndCities.statesList.find((d) => d.id === idDepartament);
    if (newCities) {
      setCitiesService(newCities.city.sort((a, b) => {
        const x = a.cityName < b.cityName ? -1 : 1;
        return x;
      }));
    }
    return idDepartament;
  };

  // ---------------------------------------------
  // event handler boton aceptar componente alerta
  // ---------------------------------------------
  const handleClickOkAlert = () => {
    switch (typeAlert) {
      case 'registerCashPurchase':
        setOpenAlertInfo(false);
        if (referenceUuidPurchaseOrder) {
          // redirecciona a resumen de compra
          history.push(`/summaryorder/${referenceUuidPurchaseOrder}`);
        } else {
          // redirecciona a home de paquetes
          history.push('/');
        }
        break;
      case 'approveMailCode':
        // guarda orden de compra
        savePurchaseOrder();
        break;
      case 'cashPaymentPurchase':
        savePurchase(dataForm, paymentMethod, appName, history, null, null, geoCodeService);
        break;
      case 'changeCustomer':
        if (paymentMethod === 'EN_LINEA' || paymentMethod === 'SIN_PAGO') {
          savePurchase(dataForm, paymentMethod, appName, history, null, null, geoCodeService);
        } else {
          setMsgAlert({
            category: 'cashPaymentPurchase',
            typeMsg: 'Confirm',
            tittleMsg: 'Pago en efectivo',
            message: 'Para pagar en efectivo debes imprimir la orden de pedido que te vamos a enviar al correo y seguir las instrucciones que allí se especifican',
            textButtonAcept: 'Enviar orden',
          });
        }
        break;
      case 'errorRegisterPayment':
        setLoadingView(true);
        resetFormRegister();
        setOpenAlertInfo(false);
        handleGoToLandingPage();
        break;
      case 'activatePlan':
        setOpenAlertInfo(false);
        // redirecciona a url de aplicación
        window.location.href = redirectUrlAppName(appName);
        break;
      default:
        break;
    }
  };

  // ---------------------------------------------
  // event handler boton cancelar componente alerta
  // ---------------------------------------------
  const handleClickCancelAlert = () => {
    switch (typeAlert) {
      case 'errorRegisterPayment':
        setOpenAlertInfo(false);
        break;
      case 'activatePlan':
        resetFormRegister();
        setOpenAlertInfo(false);
        // redirecciona a url de aplicación
        window.location.href = redirectUrlAppName(appName);
        break;
      default:
        setOpenAlertInfo(false);
        break;
    }
  };

  // ---------------------------------------------
  // validación documento
  // ---------------------------------------------
  const validateDocument = (persontype, watchDocType) => {
    if (watchDocType === 6) {
      return Rules.pasaporte;
    } if (watchDocType === 4 && persontype === 'J') {
      return Rules.tipoDocumentoNIT;
    }
    return Rules.otrosDocumentos;
  };

  const validateDoc = (docType, e) => {
    const alfanumerico = /[a-zA-Z0-9]/gi;
    const numerico = /[0-9]/gi;
    if (docType === 6) {
      if (!e.key.match(alfanumerico)) {
        e.preventDefault();
      }
    } else if (!e.key.match(numerico)) {
      e.preventDefault();
    }
  };

  /**
   * event handler ingreso de teclas validas
   * @param {*} e event
   * @param {*} watchDocType tipo de documento
   * @returns valida si la tecla ingresada es valida
   */
  const handleKeyDown = (e, watchDocType) => {
    const { keyCode } = e;
    if (keyCode === 9 || keyCode === 8 || keyCode === 17 || keyCode === 37 || keyCode === 39 || keyCode === 46) return;
    validateDoc(watchDocType, e);
  };


  const setDocumentTypeLegalRep = () => {
    let documents = [];
    const docTypes = [];
    documents = documentTypes.filter((doc) => doc.value === 1 || doc.value === 2 || doc.value === 6);

    if (documents.length > 0) {
      documents.map((doc, index) => (
        docTypes.push({
          id: index,
          label: doc.text,
          value: doc.value,
        })
      ));
    }
    return docTypes;
  };

  /**
   * metodo Loading
   * @returns retorna loading circular
   */
  const getLoading = () => (
    <>
      <Grid item xs={1} sm={1}>
        <CircularProgress size={24} className={classes.buttonProgress} />
      </Grid>
    </>
  );

  /**
   * Metodo validar digito de verificación
   * @param {*} referencia digito de verificacion
   * @returns retorna caja de digito de verificació
   */
  const boxCheckDigit = (referencia) => (
    <>
      <Grid item xs={1} sm={1}>
        <Box pt={1} style={{ textAlign: 'center' }}>-</Box>
      </Grid>
      <Grid item xs={2} sm={2}>
        <Controller
          as={(
            <TextField
              size="small"
              label="Dv"
              disabled
              variant="outlined"
              style={{ color: 'red' }}
            />
          )}
          name={referencia}
          control={control}
        />
      </Grid>
    </>
  );

  // ---------------------------------------------
  // Bloqueo tecla enter
  // ---------------------------------------------
  const skipEnterPress = (e) => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };

  const handleOnchangeDepartament = (data) => {
    setValueDepartamentService(data);
    handleChangeStateCustomerServiceId(data.value);
  };

  const handleOnchangeCity = (data) => {
    setValueCityService(data);
  };

  const viewFormRegister = () => {
    if (!referenceUuidPurchaseOrder) {
      return true;
    } if (referenceUuidPurchaseOrder && code === 'OK16') {
      return true;
    } if (referenceUuidPurchaseOrder && code === 'OK17') {
      return false;
    } if (referenceUuidPurchaseOrder && code === 'OK18') {
      return false;
    }
  };

  if (loading || loadingView) return <Loading />;
  if (loadingGral) return <Loading />;

  return (
    <>
      <Container component="main" maxWidth="md">
        <Paper className={classes.root} elevation={5}>
          {
            viewFormRegister() ? (
              <form
                className={classes.form}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                onKeyPress={(e) => skipEnterPress(e)}
              >
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs={1}>
                    <IconButton color="primary" onClick={(e) => handleGoToSecurity(e)}>
                      <ArrowBackIosIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography align="center" className={classes.titleForm} variant="h5">
                      {`¡Prueba ${capitalizeFirstLetter(appName)} gratis!`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Divider className={classes.divider} variant="fullWidth" />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel />
                    <CustomSelect
                      label="Tipo de persona"
                      name="personTypeService"
                      options={personType}
                      control={control}
                      required
                      disabled={disabledToSave}
                      rule={Rules.required}
                      error={errors}
                      styleFromHelperText={{ style: { marginRight: 10 } }}
                      inputProps={{ onChange: (e) => setPersonType(e) }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid container>
                      <Grid item xs={12} md={loadingRues ? 11 : 12} lg={loadingRues ? 11 : 12}>
                        <InputLabel />
                        <CustomSelect
                          label="Tipo de documento"
                          name="documentTypeIdService"
                          // eslint-disable-next-line no-nested-ternary
                          options={getDocumentTypes(documentTypes, personTypeService, appName)}
                          control={control}
                          required
                          disabled={disabledToSave}
                          rule={Rules.required}
                          error={errors}
                          styleFromHelperText={{ style: { marginRight: 10 } }}
                          inputProps={{ onChange: (e) => setPersonType(e) }}
                        />
                      </Grid>
                      {loadingRues && getLoading()}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {
                      watchDocTypeSer === 4 // Nit
                        ? (
                          <InputLabel shrink className={classes.infoText}>
                            Sin digito de verificación
                          </InputLabel>
                        ) : (
                          ''
                        )
                    }
                    <Grid container>
                      <Grid item xs={watchDocTypeSer === 4 ? 9 : 12} sm={watchDocTypeSer === 4 ? 9 : 12}>
                        <CustomInput
                          label="Número de documento"
                          name="documentNumberService"
                          control={control}
                          rule={validateDocument(personTypeService, watchDocTypeSer)}
                          error={errors}
                          disabled={loadingRues || disabledToSave}
                          required
                          styleFromHelperText={{ style: { marginRight: 10 } }}
                          onKeyDown={(e) => handleKeyDown(e, watchDocTypeSer)}
                          inputProps={{ onBlur: () => getDefaulttNameByTypeAndDoc(personTypeService, 'documentTypeIdService', 'documentNumberService', 'Service'), maxLength: 15 }}
                        />
                      </Grid>
                      {watchDocTypeSer === 4 ? boxCheckDigit('dvService') : ''}
                    </Grid>
                  </Grid>
                  {
                    personTypeService === 'J'
                      ? (
                        <Grid item xs={12}>
                          <CustomInput
                            label="Razón Social"
                            name="legalNameService"
                            control={control}
                            rule={Rules.DenySpecialCharactersLegalName}
                            error={errors}
                            disabled={loadingRues || disabledToSave}
                            required
                            styleFromHelperText={{ style: { marginRight: 10 } }}
                            inputProps={{
                              maxLength: 450,
                            }}
                          />
                        </Grid>
                      )
                      : (
                        <>
                          <Grid item xs={12} md={6}>
                            <CustomInput
                              label="Nombres"
                              name="firstNameService"
                              control={control}
                              rule={Rules.letrasTildesMin3}
                              error={errors}
                              required
                              disabled={disabledToSave}
                              styleFromHelperText={{ style: { marginRight: 10 } }}
                              inputProps={{
                                maxLength: 100,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <CustomInput
                              label="Apellidos"
                              name="lastNameService"
                              control={control}
                              rule={Rules.letrasTildesMin3}
                              error={errors}
                              required
                              disabled={disabledToSave}
                              styleFromHelperText={{ style: { marginRight: 10 } }}
                              inputProps={{
                                maxLength: 100,
                              }}
                            />
                          </Grid>
                        </>
                      )
                  }
                  <Grid item xs={12} md={6}>
                    <CustomInput
                      label="Teléfono / Celular"
                      name="cellphoneNumberService"
                      control={control}
                      rule={Rules.telCel}
                      error={errors}
                      required
                      disabled={disabledToSave}
                      styleFromHelperText={{ style: { marginRight: 10 } }}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomInput
                      label="Correo Electrónico"
                      name="emailService"
                      control={control}
                      rule={Rules.email}
                      error={errors}
                      required
                      disabled={disabledToSave}
                      styleFromHelperText={{ style: { marginRight: 10 } }}
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AutocompleteGoogleMaps
                      name="fulladdressService"
                      options={optionsAddressService}
                      label="Dirección"
                      placeholder="Escriba la dirección"
                      value={addressMapsService}
                      setOptions={setOptionsAddressService}
                      setValueMaps={setAddressMapsService}
                      inputValue={inputAddressServiceValue}
                      setInputValue={setInputAddressServiceValue}
                      setGeoCoordinate={setGeoCodeService}
                      type="Service"
                      disabled={disabledToSave}
                      showIconLocation
                      control={control}
                      rules={Rules.required}
                      error={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomAutocompleteSelect
                      name="departmentIdService"
                      options={departaments}
                      label="Departamento"
                      disableClearable
                      value={valueDepartamentService}
                      getOptionLabel={(option) => option.text}
                      required
                      disabled={disabledToSave}
                      FormHelperTextProps={{ style: { marginRight: 10 } }}
                      handleOnchange={handleOnchangeDepartament}
                      control={control}
                      rule={Rules.required}
                      error={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomAutocompleteSelect
                      name="cityIdService"
                      options={citiesService}
                      label="Ciudad"
                      disableClearable
                      value={valueCityService}
                      getOptionLabel={(option) => option.cityName}
                      required
                      disabled={disabledToSave}
                      FormHelperTextProps={{ style: { marginRight: 10 } }}
                      handleOnchange={handleOnchangeCity}
                      control={control}
                      rule={Rules.required}
                      error={errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomInput
                      label="Información adicional"
                      name="additionalInfoService"
                      control={control}
                      error={errors}
                      disabled={disabledToSave}
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                    <Typography
                      style={{ paddingLeft: '5px', fontSize: '12px' }}
                      className={classes.contentInfoAddress}
                    >
                      Ejemplo: Interior, apartamento, conjunto residencial, barrio, etc.
                    </Typography>
                  </Grid>
                  {
                    personTypeService === 'J'
                      ? (
                        <>
                          <Grid item xs={12}>
                            <h3 className={classes.contentTextPrimary}>Datos representante legal</h3>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <CustomSelect
                              label="Tipo de documento Representante Legal"
                              name="legalDocumentTypeIdService"
                              // eslint-disable-next-line no-nested-ternary
                              options={setDocumentTypeLegalRep()}
                              control={control}
                              required
                              disabled={disabledToSave}
                              rule={Rules.required}
                              error={errors}
                              styleFromHelperText={{ style: { marginRight: 10 } }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <CustomInput
                              label="Número de documento Representante Legal"
                              name="legalDocumentNumberService"
                              control={control}
                              rule={validateDocument('J', watchLegalDocTypeSer)}
                              error={errors}
                              disabled={loadingRues || disabledToSave}
                              required
                              styleFromHelperText={{ style: { marginRight: 10 } }}
                              onKeyDown={(e) => handleKeyDown(e, watchLegalDocTypeSer)}
                              inputProps={{ maxLength: 15 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <CustomInput
                              label="Nombres Representante Legal"
                              name="legalFirstNameService"
                              control={control}
                              rule={Rules.letrasTildesMin3}
                              error={errors}
                              required
                              disabled={disabledToSave}
                              styleFromHelperText={{ style: { marginRight: 10 } }}
                              inputProps={{
                                maxLength: 100,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <CustomInput
                              label="Apellidos Representante Legal"
                              name="legalLastNameService"
                              control={control}
                              rule={Rules.letrasTildesMin3}
                              error={errors}
                              required
                              disabled={disabledToSave}
                              styleFromHelperText={{ style: { marginRight: 10 } }}
                              inputProps={{
                                maxLength: 100,
                              }}
                            />
                          </Grid>
                        </>
                      )
                      : (
                        <>
                        </>
                      )
                  }
                </Grid>
                <Grid container spacing={2} className={classes.legalTerms}>
                  <Grid item xs={1} style={{ marginLeft: '-20px' }}>
                    <CustomCheck
                      label=""
                      name="legalTermsCheck"
                      control={control}
                      rule={Rules.checkTerminosCondiciones}
                      error={errors}
                      classesFormControlLabel={{ label: classes.termsAndConditions }}
                      size="small"
                      disabled={disabledToSave}
                      defaultValue={false}
                    />
                  </Grid>
                  <Grid item xs={11} style={{ marginLeft: !isSm ? '-40px' : '0px' }}>
                    <span style={{ fontSize: '14px' }}>Autorizo el tratamiento de mis datos personales a Gestión de Seguridad Electrónica bajo su</span>
                    {!isSm ? <br /> : ' '}
                    <a href={applicationInfo?.urlPersonalDataTreatmentPolicy} className={classes.policyAndTerms} target="_blank">Política de Tratamiento de Datos Personales</a>
                    &nbsp;
                    <span style={{ fontSize: '14px' }}>y certifico que he leído y acepto los</span>
                    &nbsp;
                    <a href={applicationInfo?.urlTermsAndConditions} className={classes.policyAndTerms} target="_blank">Términos y Condiciones</a>
                    {errors.legalTermsCheck && (
                      <Typography className={classes.formErrors}>
                        {errors.legalTermsCheck.message}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Box className={classes.submit}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.btnSubmit}
                    disabled={disabledToSave || disabledSubmit}
                    onClick={() => {
                      setPaymentMethod('SIN_PAGO');
                    }}
                  >
                    Comenzar
                  </Button>
                </Box>
                <Box className={classes.submit}>
                  <Button href="/" className={classes.buyNow} color="primary">
                    Comprar ahora
                  </Button>
                </Box>
              </form>

            ) : (
              <>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs={12}>
                    <Typography align="center" className={`${classes.titleActivated}`} variant="h5">
                      ¡Revisa tu Email!
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} className={classes.alertActivation}>
                    <img src={CheckEmail} alt="Revisa tu email" className={classes.logoActivated} />
                  </Grid>
                  <Grid item xs={12} className={classes.alertActivation}>
                    <Grid item xs={6}>
                      <Typography variant="body2" className={classes.messageActivated} color="textSecondary">
                        Hemos enviado a tu email el link para que actives tu usuario, haz clic en él para continuar con el proceso de activación de tu prueba gratuita.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.alertActivation}>
                    <Grid item xs={5}>
                      <Typography variant="body2" className={classes.timeActivation} color="textSecondary">
                        {'Recuerda que cuentas con un máximo de '}
                        <br />
                        <span style={{ fontWeight: 700 }}>
                          {applicationInfo.userActivateTokenTime}
                        </span>
                        {' para activarlo.'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )
          }
        </Paper>
        {/* alerta informativa o error */}
        {
          showMessageAlert
          && (
            <AlertConfirm
              isOpen={openAlertInfo}
              tituloModal={titleAlert}
              styleTitle={{ fontSize: 18 }}
              mensajeModal={messageAlert}
              handleAccept={handleClickOkAlert}
              showClose={showCloseAlert || showButtonCancel}
              textButtonAcept={textButtonAccept}
              onCancel={showButtonCancel}
              onClose={handleClickCancelAlert}
              isOnlyMessage={isOnlyMessage}
              textAlignMessage="center"
              messageAlert={showMessageAlert}
            >
              {
                subMensaje && (
                  <Typography className={classes.secondaryMessageAlert}>
                    {subMensaje}
                  </Typography>
                )
              }
            </AlertConfirm>
          )
        }
      </Container>
    </>
  );
};

const mapStateToProps = (props) => ({
  userInfo: props.auth.userInfo,
  loading: props.purchaseLicense.loading,
  loadingGral: props.general.loading,
  getError: props.purchaseLicense.getError,
  billingInfo: props.purchaseLicense.customerBillingInfo,
  serviceInfo: props.purchaseLicense.customerServiceInfo,
  typeLoad: props.purchaseLicense.typeLoad,
  validityEmail: props.purchaseLicense.validityEmail,
  validityChangeCustomer: props.purchaseLicense.validityChangeCustomer,
  showMessageAlert: props.purchaseLicense.showMessageAlert,
  referenceUuidPurchaseOrder: props.purchaseLicense.referenceUuidPurchaseOrder,
  code: props.purchaseLicense.code,
  documentTypes: props.general.documentTypes,
  statesAndCities: props.general.statesAndCities,
  applicationInfo: props.general.applicationInfo,
  cart: CleanCart(props.home.cart),
  appName: props.general.appName,
  citiesServGoogleAssistant: props.google.citiesServGoogleAssistant,
});

const mapDispatchToProps = {
  getPurchaseOrderParameters: getPurchaseOrderParametersAction,
  savePurchase: savePurchaseAction,
  getCustomerInformation: getCustomerInformationAction,
  getNameByTypeDocAndDoc: getNameByTypeDocAndDocAction,
  getTokenExternalSource: getTokenExternalSourceAction,
  validityChangeCustomerAct: validityChangeCustomerAction,
  setMsgAlert: setMessageAlertAction,
  resetFormRegister: resetFormRegisterAction,
  setShowCart: setShowCartAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivatePlan);
